import { extendTheme } from '@chakra-ui/react';
import { StepsTheme as Steps } from 'chakra-ui-steps';

const theme = extendTheme({
  components: {
    Steps,
  },
  // fonts: {
  //   heading: 'Avenir',
  //   body: 'Avenir',
  // },
  colors: {
    brand: {
      main: "#EF8967",
      100: "#fcebe5",
      200: "#f7c3b2",
      300: "#f29b7f",
      400: "#ec744c",
      500: "#e74c18",
      600: "#b33b13",
      700: "#802a0d",
      800: "#4d1908",
      900: "#1a0803",

    },
    secondary: {
      main: "#0B3866",
      100: "#e5f1fc",
      200: "#b2d4f7",
      300: "#7fb8f1",
      400: "#4c9bec",
      500: "#197ee6",
      600: "#1362b3",
      700: "#0e4680",
      800: "#082a4d",
      900: "#030e1a",
    }
  }
})

export default theme
