import { ChakraProvider } from "@chakra-ui/react";
import { useEffect } from "react";
import "styles/globals.css";
import Fonts from "utils/fonts";
import theme from "utils/theme";
import { supabase } from "utils/supabaseClient";
import Head from "next/head";
import NProgress from "nprogress";
import { useRouter } from "next/router";
import { init } from "@socialgouv/matomo-next";
import image from "public/images/meta-banner.png";

const MATOMO_URL = 'https://matomo.codingtree-studio.com/';
const MATOMO_SITE_ID = 2;


function MyApp({ Component, pageProps }) {

  const router = useRouter();

  useEffect(() => {

    init({ url: MATOMO_URL, siteId: MATOMO_SITE_ID });

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        updateSupabaseCookie(event, session);
      }
    );

    const handleStart = (url) => {
      NProgress.start();
    };
    const handleStop = () => {
      NProgress.done();
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleStop);
    router.events.on("routeChangeError", handleStop);

    return () => {
      authListener?.unsubscribe();
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleStop);
      router.events.off("routeChangeError", handleStop);
    };
  }, [router]);


  async function updateSupabaseCookie(event, session) {
    await fetch("/api/auth", {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      credentials: "same-origin",
      body: JSON.stringify({ event, session }),
    });
  }

  return (
    <ChakraProvider theme={theme}>
      <Head>
        <title>Tête en l'air</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Fonts />
      <Component {...pageProps} />
    </ChakraProvider>
  );
}

export default MyApp;
