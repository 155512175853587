import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: "Avenir";
        src: url("/fonts/Avenir/Avenir-Regular.ttf");
        font-style: normal;
        font-weight: 400;
        font-display: swap;
      }
      @font-face {
        font-family: "Avenir";
        src: url("/fonts/Avenir/Avenir-Bold.ttf");
        font-style: medium;
        font-weight: 800;
        font-display: swap;
      }
      `}
  />
)

export default Fonts